import { useState } from 'react';
import { useIsomorphicLayoutEffect } from '@lib/hooks'
import { StyleSheet } from 'react-native';

const styleXxl = StyleSheet.create({
  card: {
    width: '288px',
    height: '257px'
  }
})

const styleXl = StyleSheet.create({
  card: {
    width: '270px',
    height: '245px'
  }
})
const styleLg = StyleSheet.create({
  card: {
    width: '254px',
    height: '230px'
  }
})
const styleMd = StyleSheet.create({
  card: {
    width: '229px',
    height: '208px'
  }
})
const styleSm = StyleSheet.create({
  card: {
    width: '190px',
    height: '170px',
    marginRight: '10px'
  }
})

export const useStyle = () => {
  const [style, setStyle] = useState(styleXxl);
  useIsomorphicLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(([entry]) => {
      const { contentBoxSize: [{ inlineSize }] } = entry;
      if (inlineSize >= 1200) {
        setStyle(styleXxl)
      }
      if (inlineSize < 1200 && inlineSize >= 1120) {
        setStyle(styleXl)
      }
      if (inlineSize < 1120 && inlineSize >= 1060) {
        setStyle(styleLg)
      }
      if (inlineSize < 1060 && inlineSize > 960) {
        setStyle(styleMd)
      }
      if (inlineSize < 961) {
        setStyle(styleSm)
      }
    });
    resizeObserver.observe(document.body);
    return () => {
      resizeObserver.unobserve(document.body)
    }
  }, [])
  return style
}
