import React, { useState } from 'react';
import I18n from '@utils/i18n';
import { Swiper } from '@element/index';
import { ModuleTitle } from '../ModuleTitle';
import { useIsomorphicLayoutEffect } from '@lib/hooks'
import MemoizedStarPeople from '@components/MemoizedStarPeople'
import cn from 'classnames'
import styles from './index.module.css'
import * as API from '@api/index';

const renderItem = (item, index) => {
  return (
        <MemoizedStarPeople
            item={item}
            key={'star' + index}
            // navigation={this.props.navigation}
            navigation={{ navigate: (name) => { console.log(name) } }}
        />
  )
}

const KikiMaster = (props) => {
  const [eventCenterConfig, setEventCenterConfig] = useState([])

  useIsomorphicLayoutEffect(() => {
    API.homeKols().then((response) => {
      console.log(response, 'resresres')
      setEventCenterConfig((response.result?.res?.rows) || [])
    })
  }, [])

  return <>
    <ModuleTitle><h2 className='DINPro-Medium'>{I18n.t('rsOvirVL')}</h2></ModuleTitle>
    <Swiper
      dataSource={eventCenterConfig}
      pageSize={4}
      renderItem={renderItem}
      ItemSeparatorMargin={'16px'}
      data-buried="rsOvirVL"
      listClass={cn(styles.list, { [styles.listLeft]: eventCenterConfig?.length < 4 })}
      itemClass={cn(styles.item)}
    />
  </>;
}

export default KikiMaster
