import React from 'react';
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity
} from 'react-native';
import FastImage from 'react-native-fast-image';
import I18n from '@utils/i18n';
import p from '@utils/Transfrom';
import helper from '@utils/helper'
import { useStyle } from './useStyle'
import { paddingHorizontal, imageRounded40, fontWeight, radius, padding, fontFamily } from '@styles/common'
/**
 * 当前资金费率
 */
function FundingFeeView (props) {
  const style = useStyle();
  return (
    <TouchableOpacity activeOpacity={0.8} onPress={() => {
      // try {
      helper.trackEvent('NewHome Click ExploreDataBTCFunding', { item: props.item ? JSON.stringify(props.item) : '' });
      // } catch (e) {
      // }

      // props.navigation.navigate('ExploreDataBTCFunding', { TRACK_PARAM: helper.toTrackParam(props.item?.type ?? '', props.item?.exchange ?? '', props.item?.symbol ?? '', props.item?.interval ?? '') });
    }}>
      <View style={StyleSheet.flatten([styles.card, style.card])}>
        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: p(64) }}>
            {props.symbolBTC?.picturePath && <FastImage
              source={{ uri: props.symbolBTC.picturePath }}
              style={imageRounded40}
            />}
            <View style={styles.iconView}>
              {props.symbolETH?.picturePath && <FastImage
                source={{ uri: props.symbolETH.picturePath }}
                style={StyleSheet.flatten([imageRounded40])}
              />}
            </View>
        </View>
        <View style={{
          justifyContent: 'center'
        }}>
          <Text style={[styles.coin, {
            fontSize: p(46),
            lineHeight: p(59),
            marginBottom: p(24),
            marginTop: p(40),
            fontFamily: fontFamily.bold,
            fontWeight: fontWeight.f700
          }]}>BTC / ETH</Text>

          <Text style={[styles.coin]}>{I18n.t('funding_rate')}</Text>
        </View>

      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  iconView: {
    marginLeft: '8px'
  },
  card: {
    width: '226px',
    height: '205px',
    paddingTop: padding.p30,
    backgroundColor: '#F6F8FB',
    borderRadius: radius.r36,
    paddingHorizontal: paddingHorizontal.ph24
  },
  coinRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: p(7)
  },
  coin: {
    fontSize: p(24),
    color: '#1F2126',
    lineHeight: p(33),
    textAlign: 'center'
  }

})

const MemoizedFundingFeeView = React.memo(FundingFeeView);
export default MemoizedFundingFeeView
