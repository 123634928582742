import React from 'react';
import {
  View,
  StyleSheet,
  Text,
  ImageBackground
} from 'react-native';
import { COMMUNITY_PATHS } from '@constants/index'
import Link from 'next/link'
import { alignItems, width, height, radius, paddingHorizontal, justifyContent, lineHeight, colors, fontSize } from '../styles/common'
import styleModal from '@styleModules/memoizedStarPeople.module.css';

function StarPeople ({ item }) {
  return (
      <Link href={`${COMMUNITY_PATHS.USER_HOME}${item.customerId}`}>
      <a>
        <div className={styleModal.imgContent}>
          <ImageBackground
            source={{ uri: item.avatar }}
            style={styles.starPeople}
          >
            <View style={styles.blurWrap}>
              <ImageBackground
                source={{ uri: item.avatar }}
                blurRadius={80}
                style={styles.blurImageStyle}>
              <div className={styleModal.title}>
                <View
                  style={styles.titleSection}
                >
                  <Text
                    style={styles.starPeopleName}
                  >
                    {item.nickName}
                  </Text>
                  <div className={styleModal.text}>
                    <Text style={styles.starPeopleIntro}>{item.intro}</Text>
                  </div>
                  </View>
              </div>
              </ImageBackground>
            </View>
          </ImageBackground>
        </div>
        </a>
      </Link>
  )
}

const styles = StyleSheet.create({
  starPeople: {
    height: height.h684,
    borderRadius: '12px',
    backgroundColor: colors.red1,
    justifyContent: 'flex-end',
    overflow: 'hidden'
  },
  blurWrap: {
    height: height.h212,
    width: '100%',
    borderBottomLeftRadius: '12px',
    borderBottomRightRadius: '12px',
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    backdropFilter: 'blur(15px)'
  },
  blurImageStyle: {
    width: '100%',
    height: height.h680,
    borderRadius: '12px',
    position: 'absolute',
    bottom: 0,
    justifyContent: 'flex-end'
  },
  titleSection: {
    height: height.h212,
    width: '100%',
    borderBottomLeftRadius: '12px',
    borderBottomRightRadius: '12px',
    backgroundColor: colors.transparent,
    paddingHorizontal: paddingHorizontal.ph30,
    paddingTop: '20px'
  },
  starPeopleName: {
    color: colors.pureWhite,
    fontSize: '16px',
    lineHeight: '21px'
  },
  starNameRow: {
    flexDirection: 'row',
    alignItems: alignItems.center,
    justifyContent: justifyContent.spaceBetween
  },
  starPeopleIntro: {
    color: colors.white6,
    fontSize: fontSize.f24,
    lineHeight: '16px',
    marginTop: '10px'
  },
  starFollow: {
    width: width.w120,
    height: height.h48,
    borderRadius: radius.r24,
    backgroundColor: colors.white7,
    alignItems: alignItems.center,
    justifyContent: justifyContent.center
  },
  starFollowText: {
    color: colors.pureWhite,
    fontSize: fontSize.f24,
    lineHeight: lineHeight.l31
  }
})

const MemoizedStarPeople = React.memo(StarPeople);
export default MemoizedStarPeople
