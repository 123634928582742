import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import cn from 'classnames';
import styles from './index.module.css';
import { useIsomorphicLayoutEffect } from '@lib/hooks/useIsomorphicLayoutEffect';
import { Tabs, TabPane } from '@element/index';
import List from '@widget/market/TradeList/List';
import { getListData } from '@widget/market/TradeList/utils';
import { MarketAPI } from '@api/market';
import I18n from '@utils/i18n';
import { useRouter } from 'next/router';
import { MARKET_PATHS } from '@constants/index';
import { ModuleTitle } from '@widget/home/ModuleTitle';

const pageSize = 5;

const T = (props) => {
  const router = useRouter();
  const {
    marketStore,
    marketStore: { fetchMarketCoin, fullMarketSymbols }
  } = props;
  const [loading, setLoading] = useState(false);
  const [newCoinsList, setNewCoinsList] = useState(false);
  const [activeKey, setActiveKey] = useState('hot');

  const getHotCoinsData = () => {
    const dataSource = getListData('USDT', marketStore);
    return dataSource.slice(0, pageSize);
  };

  const getLatestCoinlist = () => {
    return new Promise((resolve, reject) => {
      MarketAPI.coinList({ sort: 'created' })
        .then((response) => {
          if (response && response.success && response.obj) {
            const listData = response.obj.slice(0, pageSize);
            listData?.forEach((item) => {
              marketStore.marketSymbols?.forEach((_item) => {
                if (_item.coinCode === item.coinCode + '_USDT') {
                  item.coinCode = _item.coinCode;
                  item.riseAndFall = _item.riseAndFall;
                  // item.priceLast = _item.priceLast
                }
              });
            });
            resolve(listData);
          }
        })
        .catch((error) => {
          resolve([]);
          console.log(error);
        });
    });
  };

  const clickMore = () => {
    router.push({
      pathname: MARKET_PATHS.MARKET
    });
  };

  useIsomorphicLayoutEffect(() => {
    async function fetchData () {
      setLoading(true);
      const response = await Promise.all([
        fetchMarketCoin(),
        fullMarketSymbols(),
        getLatestCoinlist()
      ]);
      setNewCoinsList(response[2]);
      setLoading(false);
    }
    fetchData();
  }, []);

  return (
    <div className={styles.container}>
      <ModuleTitle>
        <h2 className={cn('Medium')}>{I18n.t('home_market')}</h2>
      </ModuleTitle>
      <Tabs
        type="marketTabCoin"
        activeKey={activeKey}
        onChange={(activeKey) => setActiveKey(activeKey)}
        className={styles.tabs}
        tabBarExtraContent={
          <span
            data-buried="more"
            className={styles.moreBtn}
            onClick={clickMore}
          >
            {I18n.t('more')}
          </span>
        }
      >
        <TabPane
          tab={<h3 data-buried="hotCoin">{I18n.t('hotCoin')}</h3>}
          type="marketTabCoin"
          key="hot"
        >
          <List
            dataSource={getHotCoinsData()}
            loading={loading}
            page="HOME_HOT"
          />
        </TabPane>
        <TabPane
          tab={
            <h3 data-buried="newCoin" className="DINPro">
              {I18n.t('newCoin')}
            </h3>
          }
          type="marketTabCoin"
          key="new"
        >
          <List dataSource={newCoinsList} loading={loading} page="HOME_NEW" />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default inject((rootStore) => {
  return {
    marketStore: rootStore.store.marketStore,
    tradeStore: rootStore.store.tradeStore
  };
})(observer(T));
