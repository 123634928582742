import React from 'react';
import cn from 'classnames';
import helper from '@utils/helper';
import I18n from '@utils/i18n';
import map from 'lodash/map';
import { Swiper } from '@element/index';
import { ModuleTitle } from '../ModuleTitle';
import { inject, observer } from 'mobx-react'
import { useIsomorphicLayoutEffect } from '@lib/hooks'
import MemoizedLongShortData from './MemoizedLongShortData'
import MemoizedLiquidationView from './MemoizedLiquidationView'
import MemoizedAnalysisView from './MemoizedAnalysisView'
import MemoizedFundingFeeView from './MemoizedFundingFeeView'
import { useData } from './useData'
import styles from './index.module.css'

const renderItem = (coinMap, item, index) => {
  const symbol = coinMap[item.symbol] || {};
  if (item.type === 'long_short') {
    const data = map(item.dataList ?? [], 'value');
    return <MemoizedLongShortData item={item} symbol={symbol} data={data}/>
  }
  if (item.type === 'liquidation') {
    return <MemoizedLiquidationView item={item} symbol={symbol} />
  }
  if (item.type === 'analysis') {
    const symbolBTC = coinMap.BTC || {};
    return <MemoizedAnalysisView item={item} symbol={symbolBTC} languageCode={helper.getLocaleKey(I18n.locale)} />
  }
  if (item.type === 'funding_fee') {
    const symbolBTC = coinMap.BTC || {};
    const symbolETH = coinMap.ETH || {};
    return <MemoizedFundingFeeView item={item} symbolBTC={symbolBTC} symbolETH={symbolETH} />
  }
  return (
    <div />
  )
}

export const T = ({ marketStore }) => {
  const { marketCoins } = marketStore;
  const { getDataArr } = useData()
  useIsomorphicLayoutEffect(() => {
    if (!marketCoins?.length) {
      marketStore.fetchMarketCoin()
    }
  }, [])
  const arr = getDataArr()
  return <>
    <ModuleTitle><h2 className='Medium'>{I18n.t('data')}</h2></ModuleTitle>
    <Swiper
      dataSource={arr}
      pageSize={4}
      data-buried="data"
      renderItem={(...rest) => {
        const coinMap = marketCoins.reduce((prev, curr) => {
          prev[curr.coinCode] = curr;
          return prev
        }, {})
        return renderItem(coinMap, ...rest)
      }
      }
      listClass={cn(styles.list, { [styles.listLeft]: arr?.length < 5 })}
      itemClass={cn(styles.item, { [styles.listMargin]: arr?.length < 5 })}
    />
  </>;
}

export const Data = inject(rootStore => ({
  marketStore: rootStore.store.marketStore
}))(observer(T))
