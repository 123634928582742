import React from 'react';
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity
} from 'react-native';
import FastImage from 'react-native-fast-image';
import { MARKET_PATHS } from '@constants/index';
import { alignItems, fontFamily } from '../styles/common'
import useClient from '@lib/hooks/useClient';
import { useRouter } from 'next/router';

function ThemeItem ({ item }) {
  const isPc = useClient()
  const router = useRouter()
  const containerStyles = isPc
    ? {
        height: 80,
        borderRadius: 18,
        paddingHorizontal: 16
      }
    : {
        width: 190,
        height: 70,
        borderRadius: 20,
        paddingHorizontal: 12
      }

  const imgStyle = isPc
    ? {
        width: 48,
        height: 48,
        borderRadius: 48
      }
    : {
        width: 40,
        height: 40,
        borderRadius: 40
      }
  const textStyle = isPc
    ? {
        fontSize: 16,
        marginLeft: 16
      }
    : {
        fontSize: 14,
        marginLeft: 12
      }

  return (
    <TouchableOpacity activeOpacity={0.8} onPress={() => {
      router.push({
        pathname: `${MARKET_PATHS.ZONES}/${item.categoryKey}`
      })
    }}>
      <View style={StyleSheet.flatten([styles.themeRowStyle, containerStyles])}>
        {!!item.picPaths[0] && <FastImage
          source={
            item.picPaths && item.picPaths.length
              ? { uri: item.picPaths[0] }
              : null
          }
          style={StyleSheet.flatten(imgStyle)}
        />}
        <Text style={StyleSheet.flatten([styles.themeTextStyle, textStyle])}>
          <span className='DINPro-Medium'>{item.localeText}</span>
        </Text>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  themeRowStyle: {
    backgroundColor: '#F6F8FB',
    alignItems: alignItems.center,
    flexDirection: 'row'
  },
  themeTextStyle: {
    color: '#434B5F',
    fontFamily: fontFamily.medium,
    fontWeight: 700
  }
})
const MemoizedThemeItem = React.memo(ThemeItem);
export default MemoizedThemeItem
