import React, { useState, useMemo, useRef } from 'react';
import { useIsomorphicLayoutEffect } from '@lib/hooks'
import { buildSvgPath } from '@utils/index'

const AreaCharts = (props) => {
  const { data, height } = props;
  const ref = useRef();
  const [width, setWidth] = useState()
  const { pathline, patharea } = useMemo(() => {
    return buildSvgPath(data, { width: width, height: parseInt(height) })
  }, [width, data])
  useIsomorphicLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(([entry]) => {
      const { contentBoxSize: [{ inlineSize }] } = entry;
      setWidth(inlineSize)
    });
    resizeObserver.observe(ref.current);
    return () => {
      resizeObserver.unobserve(ref.current)
    }
  }, [])
  return (
    <div ref={ref}>
    <svg height={height} width={width}>
      <defs>
        <linearGradient id={ 'gradient' } x1={ '0%' } y={ '0%' } x2={ '0%' } y2={ '100%' }>
          <stop offset={ '0%' } stopColor={ '#6F40EE' } stopOpacity={ 0.2 }/>
          <stop offset={ '100%' } stopColor={ '#A874F8' } stopOpacity={ 0 }/>
        </linearGradient>
        <linearGradient id={'line-gradient'} x1={'0%'} y1={'0%'} x2={'100%'} y2={'100%'}>
          <stop offset={'0%'} stopColor={'#6F40EE'} stopOpacity={0} />
          <stop offset={'25%'} stopColor={'#6F40EE'} stopOpacity={0.6} />
          <stop offset={'75%'} stopColor={'#6F40EE'} stopOpacity={0.6} />
          <stop offset={'100%'} stopColor={'#6F40EE'} stopOpacity={0} />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d={patharea} fill="url(#gradient)" />
        <path
          d={pathline}
          stroke="url(#line-gradient)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
      </g>
    </svg>
    </div>
  )
}

export default AreaCharts;
