import React, { useState } from 'react';

import cn from 'classnames';

import { SpotTradeAPI } from '@api/spotTrade';
import MemoizedThemeItem from '@components/MemoizedThemeItem'
import { Swiper } from '@element/index';
import useClient from '@lib/hooks/useClient';
import { useIsomorphicLayoutEffect } from '@lib/hooks/useIsomorphicLayoutEffect'
import helper from '@utils/helper';
import I18n from '@utils/i18n';
import { ModuleTitle } from '@widget/home/ModuleTitle';

import styles from './index.module.css';

const T = (props) => {
  const [dataSource, setDataSource] = useState([]);
  const isPc = useClient();
  const getZoneList = async () => {
    const response = await SpotTradeAPI.getZoneList();
    if (response && response.success && response.obj) {
      setDataSource(response.obj.sort((a, b) => a.order - b.order));
    } else {
      helper.handleErrorMsg(response?.msgKey)
    }
  }

  useIsomorphicLayoutEffect(() => {
    getZoneList()
  }, [])

  const _renderItem = (item, index) => {
    const localeKey = 'zoneName' + (item.categoryKey ?? '').toUpperCase();
    item.localeText = I18n.t(localeKey);
    return (
      <MemoizedThemeItem item={item} />
    )
  };

  return (

      <div className={styles.container}>
        <ModuleTitle><h2 className={cn('Medium')}>{I18n.t('sectors')}</h2></ModuleTitle>
        <Swiper
          dataSource={dataSource}
          pageSize={4}
          renderItem={_renderItem}
          ItemSeparatorMargin={isPc ? '16px' : '10px'}
          containerClassName={styles.content}
          data-buried="sectors"
        />
      </div>
  )
}

export default T
