import React from 'react';
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity
} from 'react-native';
import FastImage from 'react-native-fast-image';
import I18n from '@utils/i18n';
import p from '@utils/Transfrom';
import helper from '@utils/helper'
import { useStyle } from './useStyle'
import { paddingHorizontal, fontWeight, radius, colors, margin, padding, imageRounded40, fontFamily } from '@styles/common'

/**
 * 仪表盘
 */
function AnalysisView (props) {
  const style = useStyle();
  const mapFearAndGreedIndex = {
    贪婪: I18n.t('greed'),
    极度贪婪: I18n.t('extremeGreed'),
    中立: I18n.t('neutral'),
    恐惧: I18n.t('fear'),
    极度恐惧: I18n.t('extremeFear')
  }
  return (
    <TouchableOpacity activeOpacity={0.8} onPress={() => {
      const { item } = props;
      // try {
      helper.trackEvent('NewHome Click ExploreAnalysis ' + item.type);
      // } catch (e) {
      // }
      // props.navigation.navigate('ExploreAnalysis', { data: item, TRACK_PARAM: helper.toTrackParam(item.type, item.title[props.languageCode]) });
    }}>
      <View style={StyleSheet.flatten([styles.card, style.card])}>
        <View>
          <View style={styles.coinRow}>
            {props.symbol?.picturePath && <FastImage
              source={{ uri: props.symbol.picturePath }}
              style={imageRounded40}
            />}
            <Text style={styles.coin}>{props.item && props.item.title && props.item.title[props.languageCode]}</Text>
          </View>
          { <Text style={styles.coin}>{mapFearAndGreedIndex[props.item.FearAndGreedIndex]}</Text> }
        </View>
        <View style={{ alignItems: 'center', marginBottom: p(48) }}>
          <FastImage
            source={require('@images/analysis.webp')}
            style={{
              width: p(264),
              height: p(144)
            }}
          />
        </View>

      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  card: {
    width: '226px',
    height: '205px',
    paddingTop: padding.p30,
    backgroundColor: '#F6F8FB',
    borderRadius: radius.r36,
    paddingHorizontal: paddingHorizontal.ph24,
    justifyContent: 'space-between'
  },
  coinRow: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  coin: {
    fontSize: p(24),
    color: colors.black1,
    lineHeight: p(33),
    marginLeft: margin.m10,
    fontWeight: fontWeight.f500,
    fontFamily: fontFamily.medium
  }

})

const MemoizedAnalysisView = React.memo(AnalysisView);
export default MemoizedAnalysisView
