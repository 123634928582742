import { useState } from 'react';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import { ExploreDataAPI } from '@api/index';
import { useIsomorphicLayoutEffect } from '@lib/hooks';

const dataSortArray = [
  { type: 'long_short', symbol: 'BTC', exchange: 'Binance' },
  { type: 'analysis', code: 'BTCUSD', FearAndGreedIndex: '' },
  { type: 'long_short', symbol: 'ETH', exchange: 'Binance' },
  { type: 'liquidation', symbol: 'BTC' },
  { type: 'long_short', symbol: 'BCH', exchange: 'Binance' },
  { type: 'funding_fee', symbol: 'BTC/ETH' }
]

export const useData = () => {
  const [longShortData, setLongShortData] = useState([]);
  const getLiquidation = async () => {
    try {
      const res = await ExploreDataAPI.getLiquidation();
      return res.result
    } catch (error) {

    }
  }
  const getLongShortList = async (item) => {
    const { symbol, exchange } = item;
    try {
      const res = await ExploreDataAPI.getLongShortList({ symbol, exchange, interval: '1h' })
      let dataList = res?.result?.items?.[0]?.dataList || [];
      dataList = sortBy(dataList, 'ts')
      dataList = reverse(dataList)
      return dataList.splice(0, 10)
    } catch (error) {

    }
  }

  const getDataList = async () => {
    try {
      const res = await ExploreDataAPI.getDataList();
      const items = res?.result?.items || [];
      let index = 0;
      while (index < items.length) {
        const item = items[index]
        if (item.type === 'long_short' && item.exchange === 'Binance') {
          items[index].dataList = await getLongShortList(item);
        }
        if (item.type === 'liquidation') {
          items[index].data = await getLiquidation()
        }
        index++
      }
      setLongShortData(items)
    } catch (error) {

    }
  }
  const getDataArr = () => {
    let arr = [];
    const filterLongShortData = longShortData.filter(
      (item) => ['long_short', 'liquidation', 'analysis', 'funding_fee'].includes(item.type)
    )
    dataSortArray.forEach((item) => {
      if (item.type === 'long_short') {
        const longShortItem = filterLongShortData.filter(
          (_item) => item.symbol === _item.symbol && item.exchange === _item.exchange && item.type === _item.type
        )
        arr = arr.concat(longShortItem)
      } else if (item.type === 'liquidation' || item.type === 'analysis' || item.type === 'funding_fee') {
        const longShortItem = filterLongShortData.filter(
          (_item) => (item.symbol === _item.symbol || item.code === _item.code) && item.type === _item.type
        )
        arr = arr.concat(longShortItem)
      }
    })
    return arr;
  }
  useIsomorphicLayoutEffect(() => {
    getDataList()
  }, [])
  return {
    getDataArr
  }
}
