import React from 'react';
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity
} from 'react-native';
import FastImage from 'react-native-fast-image';
import I18n from '@utils/i18n';
import helper from '@utils/helper'
import { useStyle } from './useStyle'
import { alignItems, imageRounded40, paddingHorizontal, radius, lineHeight, colors, margin, padding, fontSize, fontWeight, justifyContent, fontFamily } from '@styles/common'
import AreaChart from './AreaChart';
/**
 * 折线图
 */
function LongShortData ({ item, symbol, data }) {
  const style = useStyle();
  return (
    <TouchableOpacity onPress={() => {
      helper.trackEvent('NewHome Click ExploreDataCoin', { item: JSON.stringify(item) });
    }} activeOpacity={0.8}>
        <View style={StyleSheet.flatten([styles.dataRowStyle, style.card])}>
          <View>
            <View style={styles.dataRowHeaderStyle}>
              {symbol?.picturePath && <FastImage
                style={imageRounded40}
                source={{ uri: symbol?.picturePath }}
              />}
              <Text style={styles.dataRowHeaderTextStyle}>{item.symbol}{I18n.locale === 'en' ? ' ' : ''}{I18n.t('long_short_ratio')}</Text>
            </View>
            <Text style={styles.dataRowRadioStyle}>{helper.fixNumString(data[0], 2)}</Text>

          </View>

          {data.length > 0 ? <AreaChart data={data} height="63px" /> : <View/>}

        </View>
    </TouchableOpacity>

  )
}

const styles = StyleSheet.create({
  dataRowStyle: {
    width: '226px',
    height: '205px',
    paddingTop: padding.p30,
    paddingBottom: '24px',
    backgroundColor: '#F6F8FB',
    borderRadius: radius.r36,
    justifyContent: justifyContent.spaceBetween,
    overflow: 'hidden'
  },
  dataRowHeaderStyle: {
    flexDirection: 'row',
    paddingHorizontal: paddingHorizontal.ph24,
    alignItems: alignItems.center
  },
  dataRowHeaderTextStyle: {
    color: colors.black1,
    fontSize: fontSize.f24,
    lineHeight: lineHeight.l31,
    fontWeight: fontWeight.f500,
    marginLeft: margin.m8,
    fontFamily: fontFamily.medium
  },
  dataRowRadioStyle: {
    paddingHorizontal: paddingHorizontal.ph24,
    color: colors.black,
    fontSize: '22px',
    lineHeight: lineHeight.l59,
    fontFamily: fontFamily.bold,
    fontWeight: fontWeight.f700,
    marginTop: margin.m24
  }

})

const MemoizedLongShortData = React.memo(LongShortData);
export default MemoizedLongShortData
