import React from 'react';
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity
} from 'react-native';
import FastImage from 'react-native-fast-image';
import I18n from '@utils/i18n';
import p from '@utils/Transfrom';
import helper from '@utils/helper'
import { useStyle } from './useStyle'
import { imageRounded40, paddingHorizontal, radius, fontWeight, padding, margin, fontFamily } from '@styles/common'
/**
 * 爆仓
 */
function LiquidationView (props) {
  const style = useStyle();
  return (
    <TouchableOpacity activeOpacity={0.8} onPress={() => {
      // try {
      helper.trackEvent('NewHome Click ExploreDataBTCLiquidatation', { item: JSON.stringify(props.item) });
      // } catch (e) {
      // }

      // props.navigation.navigate('ExploreDataBTCLiquidatation',
      //   { TRACK_PARAM: helper.toTrackParam(props.item.type, props.item.exchange, props.item.symbol, props.item.interval) });
    }}>
      <View style={StyleSheet.flatten([styles.card, style.card])}>
        <View>
          <View style={styles.coinRow}>
            {props.symbol?.picturePath && <FastImage
              source={{ uri: props.symbol.picturePath }}
              style={imageRounded40}
            />}
            <Text style={styles.coin}>{props.item.symbol}{I18n.t('liquidatation')}</Text>

          </View>
          <Text style={[styles.coin, {
            fontSize: p(44),
            lineHeight: p(59),
            fontFamily: fontFamily.bold,
            fontWeight: fontWeight.f700,
            marginTop: p(24)
          }]}>{isNaN(props.item.data?.totalBlastUsd24h) ? '--' : helper.fixNumString((props.item.data?.totalBlastUsd24h ?? 0) / 10 ** 6, 2) + 'M'}</Text>
        </View>
        <View style={{
          backgroundColor: '#EDEBF7',
          borderRadius: p(19),
          height: p(123),
          justifyContent: 'center',
          paddingHorizontal: p(25),
          marginTop: p(42)
        }}>
          <Text style={styles.coin}>24{I18n.t('hour')}</Text>
          <Text style={styles.coin}>{I18n.t('liquidatation_analysis')}</Text>
        </View>

      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({

  card: {
    width: '226px',
    height: '205px',
    paddingTop: padding.p30,
    backgroundColor: '#F6F8FB',
    borderRadius: radius.r36,
    paddingHorizontal: paddingHorizontal.ph24
  },
  coinRow: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  coin: {
    fontSize: p(24),
    color: '#000000',
    lineHeight: p(33),
    marginLeft: margin.m10,
    fontFamily: fontFamily.medium,
    fontWeight: fontWeight.f500
  }

})

const MemoizedLiquidationView = React.memo(LiquidationView);
export default MemoizedLiquidationView
