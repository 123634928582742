import React from 'react';

import { Layout } from 'antd';
import cn from 'classnames';

import NavBar from '@components/Navbar';
import { useAuthSession } from '@lib/hooks'
import HotTopic from '@widget/community/hotTopic'
import { Activity } from '@widget/home/Activity'
import Community from '@widget/home/community'
import { Data } from '@widget/home/Data'
import FooterNav from '@widget/home/FooterNav'
import KikiMaster from '@widget/home/KikiMaster';
import Markets from '@widget/home/markets'
import NewGuide from '@widget/home/NewGuide'
import QuickStart from '@widget/home/QuickStart';
import Zones from '@widget/home/zones'

import styles from '@styleModules/home/index.module.css';

const { Header } = Layout
const topicPageSize = 16
const isLimit = true

const T = () => {
  const isLogin = useAuthSession({ redirectTo: null });

  return (
    <Layout>
      <Header>
        <NavBar/>
      </Header>
      <QuickStart />
      <div className={styles.homeBgc}>
        <div className={styles.container}>
          {
            isLogin && (
              <div className={styles.activeContain}>
                <Activity />
              </div>
            )
          }
          <div className={styles.communityContain}>
            <Community />
          </div>
          <div className={styles.hotTopicContain}>
            <HotTopic
              btnClassName={cn(styles.btnClassName)}
              titleClassName={cn(styles.kikicommunityHotTitle)}
              className={cn(styles.kikicommunityHotTopic)}
              topicPageSize={topicPageSize}
              isLimit={isLimit}
            />
          </div>
          <div className={styles.marketContain}>
            <Markets />
          </div>
          {
            !isLogin && (
          <div className={styles.activeContain}>
              <Activity />
          </div>
            )
          }
          <div className={styles.kikiMasterContain}>
            <KikiMaster />
          </div>
          <div className={styles.dataContain}>
          <Data />
          </div>
          <div className={styles.zonesContain}>
          <Zones />
          </div>
          <div className={styles.guideContain}>
          <NewGuide />
          </div>
        </div>
      </div>
      <div className={styles.footerContain}>
          <FooterNav theme="dark" />
      </div>
    </Layout>

  )
}

export default T
